import React, { useEffect, useRef } from "react"
import { graphql, Link } from "gatsby"
import moment from "moment"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Social from "../components/social/social"

const AuctionDetail = ({ data, location }) => {
  const tabs = useRef(null)

  const auctionData = data.auction

  console.log(auctionData.registerUrl)

  var startDate = new Date(auctionData.startDate.seconds * 1000)
  var endDate = new Date(auctionData.endDate.seconds * 1000)
  let startDateMoment = moment(startDate)
  let endDateMoment = moment(endDate)

  let isFuture = false
  let endDateNoHours = endDate.setHours(0, 0, 0, 0)
  if (endDateNoHours >= new Date().setHours(0, 0, 0, 0)) isFuture = true

  var options = { timeStyle: "long", dateStyle: "long" }

  useEffect(() => {
    // eslint-disable-next-line no-undef
    $(tabs.current).easyResponsiveTabs({
      type: "horizontal",
      closed: false,
      tabidentify: "js-tabs-a", // The tab groups identifier
    })
  })

  return (
    <Layout>
      <SEO
        title={auctionData.title}
        description={auctionData.blurb}
        imageUrl={auctionData.detailImageUrl}
        url={location.href}
      />

      <div className="row">
        <div className="medium-6 columns product-image">
          <div className="pl1 pr1 mb6">
            <a id="launch-me" title={auctionData.title} href="#lightgallery">
              <img
                alt={auctionData.title}
                id="large-image"
                className="border"
                src={auctionData.detailImageUrl}
                width="100%"
                height=" auto"
              />
            </a>
            <div className="zoom-container">
              <div className="zoom">
                <em className="icon-search"></em>
              </div>
            </div>
          </div>

          <div
            id="lightgallery"
            className="row column xsmall-up-4 small-up-6 mt0 product-thumbs mt3mb6"
          ></div>
        </div>

        <div className="medium-6 columns pt6">
          <h1>{auctionData.title}</h1>
          {/* <p className="bold">February 25, 2019 | February 27, 2019</p> */}
          <hr />
          <p className="bold mb0">Date &amp; Time:</p>
          <p>
            Start: {startDateMoment.format("LLL")}
            <br />
            End: {endDateMoment.format("LLL")}
          </p>
          <p className="bold mb0">Information: </p>
          <p>
            Location: {auctionData.location} <br />
            About: {auctionData.blurb}
          </p>

          {!isFuture ? (
            <>
              <p className="mt6">
                This auction appears to be over. Please contact us for details
                or log into your account.
              </p>
              <a
                href={
                  auctionData.viewLotUrl.startsWith("https://ssllc.com/")
                    ? `../../${auctionData.viewLotUrl.replace(
                        "https://ssllc.com/",
                        ""
                      )}`
                    : auctionData.viewLotUrl
                }
                className="button secondary tiny wide caps"
              >
                View Lot Catalog
              </a>
            </>
          ) : auctionData.viewLotUrl ? (
            <>
              <a
                href={
                  auctionData.registerUrl.startsWith("https://ssllc.com/")
                    ? `../../${auctionData.registerUrl.replace(
                        "https://ssllc.com/",
                        ""
                      )}`
                    : auctionData.registerUrl
                }
                className="button tertiary tiny  caps"
              >
                Register for Auction
              </a>
              &nbsp;
              <a
                href={
                  auctionData.viewLotUrl.startsWith("https://ssllc.com/")
                    ? `../../${auctionData.viewLotUrl.replace(
                        "https://ssllc.com/",
                        ""
                      )}`
                    : auctionData.viewLotUrl
                }
                className="button tertiary tiny  caps ml1"
              >
                View Lot Catalog
              </a>
              <p className="mt6">
                <a
                  href={
                    auctionData.bidNowUrl.startsWith("https://ssllc.com/")
                      ? `../../${auctionData.bidNowUrl.replace(
                          "https://ssllc.com/",
                          ""
                        )}`
                      : auctionData.bidNowUrl
                  }
                  className="button secondary tiny wide caps"
                >
                  Bid Now
                </a>
              </p>
            </>
          ) : null}

          <p className="">
            <Social url={location.href} title={auctionData.title} />
          </p>
        </div>
      </div>

      <div className="row column mt8">
        <div id="js-tabs-horizontal" ref={tabs}>
          <ul className="resp-tabs-list js-tabs-a resp-htabs">
            <li>Description</li>
          </ul>
          <div className="resp-tabs-container js-tabs-a">
            <div>
              <p className="mb2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${auctionData.description}`,
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AuctionDetail

export const query = graphql`
  query($slug: String!) {
    auction(slug: { eq: $slug }) {
      title
      description
      startDate {
        seconds
      }
      endDate {
        seconds
      }
      detailImageUrl
      location
      blurb
      registerUrl
      viewLotUrl
      bidNowUrl
    }
  }
`
